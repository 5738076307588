import { BarController, BarElement, CartesianTickOptions, CategoryScale, Chart, ChartOptions, LinearScale, Tooltip } from 'chart.js'
import { merge } from 'lodash'
import Vue, { PropType } from 'vue'

Chart.register(BarController)
Chart.register(BarElement)
Chart.register(CategoryScale)
Chart.register(LinearScale)
Chart.register(Tooltip)

export default Vue.extend({
  name: 'bar-chart-js',
  props: {
    chartData: {
      type: Array as PropType<Array<BarItem>>,
      required: true
    },
    chartOptions: Object as PropType<ChartOptions>
  },
  data () {
    return {
      chartInstance: null,
    }
  },
  mounted () {
    const ticksOptions: Partial<CartesianTickOptions> = {
      color: '#202030',
      font: {
        family: 'Helvetica Neue',
        size: 12,
        weight: '400',
        lineHeight: '16px',
      }
    }

    this.chartInstance = new Chart(
      this.$refs.chart,
      {
        type: 'bar',
        data: {
          labels: this.chartData.map((row: BarItem) => row.title),
          datasets: [
            {
              data: this.chartData.map((row: BarItem) => row.value)
            }
          ]
        },
        options: merge({
          responsive: true,
          maintainAspectRatio: true,
          elements: {
            bar: {
              backgroundColor: '#C8C3FF',
              borderRadius: 4,
            }
          },
          scales: {
            xAxes: {
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: { ...ticksOptions },
            },
            yAxes: {
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: { ...ticksOptions },
            },
          }
        },
        this.chartOptions)
      }
    )
  }
})
